<template>
  <editor />
</template>

<script>
import Editor from '../components/Editor.vue';

export default {
  name: 'Home',

  components: {
    Editor,
  },
};
</script>
